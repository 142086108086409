import React, { FC, ReactElement } from 'react';

import { graphql } from 'gatsby';

import { ConnectedPage } from '../connectors';
import { SliceRenderer } from '../containers';
import { MetaDataResource, transformToMetaData } from '../models/Meta';
import { PrismicSlice } from '../models/Prismic';

interface SingleApplicationPageProps {
    data: {
        prismicApplication: {
            dataRaw: {
                body: PrismicSlice[];
                meta: MetaDataResource[];
            };
        };
    };
}

const SingleApplicationPage: FC<SingleApplicationPageProps> = ({
    data,
}): ReactElement => {
    const apiData = data.prismicApplication.dataRaw;

    const slices = apiData.body;
    const metaData = apiData.meta.length
        ? transformToMetaData(apiData.meta[0])
        : undefined;

    const backButtonData = {
        url: '/',
        label: 'Home',
    };

    return (
        <ConnectedPage metaData={metaData} className="application-detail-page">
            <SliceRenderer
                slices={slices}
                backButtonData={backButtonData}
                className="single-application-page__slice-renderer"
            />
        </ConnectedPage>
    );
};

export default SingleApplicationPage;

export const query = graphql`
    query ApplicationsPageComponents($id: String) {
        prismicApplication(id: {eq: $id}) {
            dataRaw
        }
    }
`;
